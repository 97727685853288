import React from 'react'
import type NextLink from 'next/link'
import type { LinkProps } from 'next/link'
import clsx from 'clsx'

interface ComponentProps extends React.PropsWithChildren {
  colorScheme?: 'blue' | 'purple'

  isLoading?: boolean
}

type HTMLAnchorProps = { as?: 'a' } & React.ComponentPropsWithoutRef<'a'>

type HTMLButtonProps = {
  as?: 'button'
} & React.ComponentPropsWithoutRef<'button'>

type NextLinkProps<T> = { as: typeof NextLink } & React.PropsWithChildren<
  Omit<LinkProps<T>, 'as'>
>

type ButtonProps<T> = ComponentProps &
  (HTMLAnchorProps | HTMLButtonProps | NextLinkProps<T>)

function Button<T>({ colorScheme, isLoading, ...props }: ButtonProps<T>) {
  const buttonClassName = clsx(
    'rounded-full text-white font-display text-xl py-2 px-6 inline-flex items-center whitespace-nowrap disabled:opacity-50',
    {
      'bg-[#3eb2fd] bg-gradient-to-b from-[#149bf3] to-[#4f58fd]':
        colorScheme === 'blue',
      'bg-[#c659f4] bg-gradient-to-b from-[#b547e3] to-[#b0118d]':
        colorScheme === 'purple',
    },
  )

  const Component = props.as ?? 'button'

  return (
    // @ts-expect-error this is fine
    <Component
      className={clsx(buttonClassName, props.className)}
      {...props}
      as={undefined}
    >
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {props.children}
    </Component>
  )
}

export default Button
