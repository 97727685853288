import type { SVGProps } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { BackButton } from '@/components/app-header/back-button'

export interface AppHeaderProps {
  precinct?: string
  coinCount?: React.ReactNode
  showHomeIcon?: boolean
  showBackIcon?: boolean
  selectedAvatar?: React.ReactNode
}

const HomeButton = () => (
  <Link href="/">
    <Image
      className="drop-shadow-[2px_2px_0_rgba(0,0,0,0.25)]"
      src="/images/home-button.svg"
      alt="Go home"
      height={64}
      width={64}
    />
  </Link>
)

export function AppHeader({
  precinct,
  selectedAvatar,
  coinCount,
  showHomeIcon = false,
  showBackIcon = false,
}: AppHeaderProps) {
  return (
    <header className="font-display fixed top-0 left-0 right-0 z-50">
      <TopNavSvg
        className="absolute top-0 left-0 right-0 h-12 w-full hidden lg:flex"
        preserveAspectRatio="none"
      />

      <div className="hidden lg:flex relative container mx-auto md:max-w-screen-lg">
        <div className="inline-flex w-1/3 align-top">
          {showHomeIcon && <HomeButton />}
          {showBackIcon && <BackButton />}

          <span className="mx-auto h-16 flex">{selectedAvatar}</span>
        </div>

        {precinct && (
          <div className="inline-flex align-top justify-center w-1/3 bg-ribbon-green bg-contain bg-center bg-no-repeat">
            <h1 className="text-2xl text-center my-1 py-4 px-8 whitespace-nowrap">
              {precinct}
            </h1>
          </div>
        )}

        <div className="inline-flex justify-center w-1/3">{coinCount}</div>
      </div>
      <div className="absolute top-0 left-0 right-0 h-[7.5em] w-full lg:hidden bg-white border-b-8 border-b-[#5597ff] z-0" />
      <div className="lg:hidden relative container mx-auto md:max-w-screen-lg pt-2">
        <div className=" align-top flex justify-around">
          {showHomeIcon && <HomeButton />}
          {showBackIcon && <BackButton />}

          <span className="mx-auto h-16 flex">{selectedAvatar}</span>
          <div className="inline-flex justify-center">{coinCount}</div>
        </div>

        {precinct && (
          <div className="w-full  align-top justify-center  bg-ribbon-green bg-contain bg-center bg-no-repeat">
            <h1 className="text-2xl text-center my-1 py-4 px-8 whitespace-nowrap">
              {precinct}
            </h1>
          </div>
        )}
      </div>
    </header>
  )
}

function TopNavSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1368 48" {...props}>
      <path id="top-nav-bar-poly" d="m0 0h1368v38Q684 20 0 38V0Z" />
      <use href="#top-nav-bar-poly" y="8" fill="#5597ff" />
      <use href="#top-nav-bar-poly" fill="#fff" />
    </svg>
  )
}
