import Link from 'next/link'
import Image from 'next/image'
import Button from '@/components/button/button'

interface ErrorDisplayProps {
  error: unknown
  onResetError: () => void
}

export function ErrorDisplay({ onResetError }: ErrorDisplayProps) {
  return (
    <div className="relative container mx-auto flex flex-col justify-center items-center font-body font-light flex-1 space-y-12 md:max-w-screen-lg bg-white text-center">
      <Image
        src="/images/mascots/mascot_02.png"
        alt=""
        width={160}
        height={130}
      />
      <h2 className="text-2xl">Oh no!</h2>
      <div className="text-base">
        <p>Something has gone wrong.</p>
        <p>Please go to Home:</p>
      </div>
      <div className="flex flex-col items-center space-y-2">
        <Button as={Link} href="/" colorScheme="purple">
          Home
        </Button>
        <p>or</p>
        <Button colorScheme="blue" onClick={onResetError}>
          Try again?
        </Button>
      </div>
    </div>
  )
}
