import type { PropsWithChildren } from 'react'

export default function PaperNotchBackgroundGroup({
  children,
}: PropsWithChildren) {
  return (
    <div className="container max-w-screen-xl flex-1 font-display">
      <div className="min-h-screen bg-paper-notch bg-[length:100%] pb-8">
        <div className="lg:mx-16 mx-3 pt-44 lg:pt-24 ">{children}</div>
      </div>
    </div>
  )
}
