'use client'

import { useEffect } from 'react'
import { useRollbar } from '@wl/rollbar'
import { ErrorDisplay } from '@/components/error-display/error-display'
import { Main } from '@/components/main/main'
import { AppHeader } from '@/components/app-header'
import PaperNotchBackgroundGroup from '@/components/paper-notch-background-group/paper-notch-background-group'

interface ErrorProps {
  error: Error
  reset: () => void
}

export default function Error({ error, reset }: ErrorProps) {
  const rollbar = useRollbar()

  useEffect(() => {
    // Log the error to an error reporting service
    rollbar?.error(error)
  }, [error, rollbar])

  return (
    <Main colour="orange">
      <AppHeader />
      <PaperNotchBackgroundGroup>
        <ErrorDisplay error={error} onResetError={reset} />
      </PaperNotchBackgroundGroup>
    </Main>
  )
}
